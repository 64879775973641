import React from 'react'
import styles from './Sidebar.module.scss'
import PropTypes from 'prop-types';

const Sidebar = ({children}) => {
  return (
      <aside className={styles.side}>
        {children}
      </aside>
  )
}

export default Sidebar

Sidebar.propTypes = {
  children: PropTypes.node.isRequired
}