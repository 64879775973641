import React from 'react'
import styles from './Content.module.scss'
import PropTypes from 'prop-types';

const Content = ({children}) => {
  return (
    <article className={styles.main}>
      {children}
    </article>
  )
}

export default Content

Content.propTypes = {
  children: PropTypes.node.isRequired
}
