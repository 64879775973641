import React from "react"
import styles from "./SideInfo.module.scss"
import PropTypes from "prop-types"

const SideInfo = ({ heading, info, link, linkType }) => {
  return (
    <div className={styles.info}>
      <h5 className={styles.heading}>{heading}</h5>
      {info && <div className={styles.detail}>{info}</div>}
      {link && (
        <div className={styles.detail}>
          <a href={`${linkType ? linkType : ""}${link}`}>{link}</a>
        </div>
      )}
    </div>
  )
}

export default SideInfo

SideInfo.propTypes = {
  heading: PropTypes.string.isRequired,
  info: PropTypes.string,
  link: PropTypes.string,
  linkType: PropTypes.string,
}