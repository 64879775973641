import React from 'react'
import styles from './SideProjectDetails.module.scss'
import PropTypes from 'prop-types';
import SideInfo from "../sideinfo/SideInfo"

const SideProjectDetails = ({project, type, client, place, date}) => {
  return (
    <div className={styles.projectDetails}>      
      <h3 className='sideHeading'>Detaily projektu</h3>
      <div className={styles.infoWrapper}>
        <SideInfo heading="Projekt:" info={project}/>
        <SideInfo heading="Typ:" info={type}/>
        <SideInfo heading="Klient:" info={client}/>
        <SideInfo heading="Místo:" info={place}/>
        <SideInfo heading="Datum:" info={date}/>
      </div>
    </div>
  )
}

export default SideProjectDetails

SideProjectDetails.propTypes = {
  project: PropTypes.string,
  type: PropTypes.string,
  client: PropTypes.string,
  place: PropTypes.string,
  date: PropTypes.string,
}
