import React from "react"
import Layout from "../components/layout/Layout"
import Cta from "../components/sections/Cta"
import Experience from "../components/sections/Experience"
import Projects from "../components/projects/Projects"
import SideProjects from "../components/projects/SideProjects"
import Main from "../components/pageLayout/Main"
import Sidebar from "../components/pageLayout/Sidebar"
import Content from "../components/pageLayout/Content"
import Banner from "../components/header/Banner"
import SideProjectDetails from "../components/projects/SideProjectDetails"
import SEO from "../components/SEO"
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer' 
import Gallery from "../components/gallery/Gallery"

export const query = graphql`
  query($slug: String!) {
    contentfulPridatNovyProjekt(slug: {eq: $slug}) {
        nazevProjektu
        typProjektu
        mistoProvedeni
        datum
        klient
        kratkyPopis
        hlavniPopis {json}
        googleNazev
        googlePopis
        hlavniFotka {
          description
          fluid (maxWidth: 1400, quality: 50) {
              ...GatsbyContentfulFluid
              src
          }
        } 
        largeMain: hlavniFotka {
          description
          fixed (width: 1200, quality: 50) {
              ...GatsbyContentfulFixed
              src
              width
              height
          }
        } 
        thumbMain: hlavniFotka {
          description
          fluid (maxWidth: 500, quality: 50) {
            ...GatsbyContentfulFluid
          }
        } 
        large: dalsiFotky {
          description
          fixed(width: 1200, quality: 60) {
            ...GatsbyContentfulFixed
            src
            width
            height
          }
        }
        thumbs: dalsiFotky {
          description
          fluid(maxWidth: 500, quality: 50) {
            ...GatsbyContentfulFluid
          }
        } 
        slug
    }
  }`

const projekt = ({data}) => {
  const {contentfulPridatNovyProjekt : { 
    nazevProjektu,
    typProjektu,
    mistoProvedeni,
    datum,
    klient,
    hlavniPopis,
    googleNazev,
    googlePopis,
    hlavniFotka,
    large,
    thumbs,
    largeMain,
    thumbMain,
    slug}} = data;    

    const allThumbs = thumbs ? [thumbMain, ...thumbs] : [thumbMain];
    const allLarge = large ? [largeMain, ...large] : [largeMain];

    return (
    <Layout>
      <SEO title={googleNazev && googleNazev} description={googlePopis && googlePopis}/>
      <Banner
        heading={nazevProjektu}
        img={hlavniFotka.fluid}
      />
      <Main>
        <Sidebar>
          <SideProjectDetails
            project={nazevProjektu}
            type={typProjektu}
            client={klient}
            place={mistoProvedeni}
            date={datum}
          />
          <SideProjects slug={slug}/>
        </Sidebar>
        <Content> 
          <div> 
            <h3 className='no-line'>{nazevProjektu}</h3>
            {documentToReactComponents(hlavniPopis.json)}
          </div>
          <h3 className='no-line u-margin-top'>Fotogalerie</h3>
          <Gallery large={allLarge} thumb={allThumbs}/>
        </Content>
      </Main>
      <Cta/>
      <Experience/>
      <Projects slug={slug}/>
    </Layout>
    )
}

export default projekt;
