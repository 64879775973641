import React from 'react'
import styles from './Main.module.scss'
import PropTypes from 'prop-types';

const Main = ({children}) => {
  return (
    <main className={styles.main}>
      {children}
      <div className={styles.underlay} />
    </main>
  )
}

export default Main

Main.propTypes = {
  children: PropTypes.node.isRequired
}