import React, { useEffect, useRef } from "react"
import "../../../node_modules/photoswipe/dist/photoswipe.css"
import "../../../node_modules/photoswipe/dist/default-skin/default-skin.css"
import PhotoSwipe from "../../../node_modules/photoswipe/dist/photoswipe.js"
import PhotoSwipeUI_Default from "../../../node_modules/photoswipe/dist/photoswipe-ui-default.js"
import PropTypes from "prop-types"

const PhotoSwipeWrapper = ({ isOpen, index, items, onClose }) => {
  let pswpElement = useRef(null)

  const options = {
    index: index || 0,
    closeOnScroll: false,
    history: false,
    showHideOpacity: true,
    getThumbBoundsFn: false,
    shareEl: false,
    captionEl: false,
  }

  useEffect(() => {
    const photoSwipe = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      items,
      options
    )

    if (photoSwipe) {
      if (isOpen) {
        photoSwipe.init()

        photoSwipe.listen("destroy", () => {
          onClose()
        })

        photoSwipe.listen("close", () => {
          onClose()
        })
      }
      if (!isOpen) {
        onClose()
      }
    }
  }, [isOpen, index, items, onClose, options])

  return (
    <div
      className="pswp"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={node => {
        pswpElement = node
      }}
    >
      <div className="pswp__bg" />
      <div className="pswp__scroll-wrap">
        <div className="pswp__container">
          <div className="pswp__item" />
          <div className="pswp__item" />
          <div className="pswp__item" />
        </div>
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            <div className="pswp__counter" />
            <button
              className="pswp__button pswp__button--close"
              title="Close (Esc)"
              aria-label="close"
            />
            <button
              className="pswp__button pswp__button--share"
              title="Share"
              aria-label="share"
            />
            <button
              className="pswp__button pswp__button--fs"
              title="Toggle fullscreen"
              aria-label="fullscreen"
            />
            <button
              className="pswp__button pswp__button--zoom"
              title="Zoom in/out"
              aria-label="zoom"
            />
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut" />
                </div>
              </div>
            </div>
          </div>
          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip" />
          </div>
          <button
            className="pswp__button pswp__button--arrow--left"
            title="Previous (arrow left)"
            aria-label="prev"
          />
          <button
            className="pswp__button pswp__button--arrow--right"
            title="Next (arrow right)"
            aria-label="next"
          />
          <div className="pswp__caption">
            <div className="pswp__caption__center" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotoSwipeWrapper

PhotoSwipeWrapper.propTypes = {
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
}