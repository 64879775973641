import React, { useState } from "react"
import PhotoSwipeWrapper from "./PhotoSwipeWrapper"
import styles from "./Gallery.module.scss"
import Img from "gatsby-image"
import { MdZoomOutMap } from "react-icons/md"
import PropTypes from "prop-types"

const Gallery = ({ thumb, large }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const thumbs = thumb.map(thumb => {
    return {
      fluid: thumb.fluid,
      desc: thumb.description,
    }
  })

  const items = large.map(large => {
    return {
      src: large.fixed.src,
      w: large.fixed.width,
      h: large.fixed.height,
      fixed: large.fixed,
      desc: large.description,
    }
  })

  const handleOpen = index => {
    setIsOpen(true)
    setIndex(index)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div className={styles.gallery}>
        {thumbs.map((thumb, i) => (
          <button
            className={styles.imgBox}
            key={i}
            onClick={() => {
              handleOpen(i)
            }}
          >
            <Img
              className={styles.img}
              fluid={thumb.fluid}
              alt={thumb.desc ? thumb.desc : "Ago-top realizace"}
            />
            <MdZoomOutMap className={styles.icon} />
          </button>
        ))}
      </div>
      <PhotoSwipeWrapper
        isOpen={isOpen}
        index={index}
        items={items}
        onClose={handleClose}
      />
    </>
  )
}

export default Gallery

Gallery.propTypes = {
  thumb: PropTypes.array.isRequired,
  large: PropTypes.array.isRequired
}
