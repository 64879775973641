import React from "react"
import styles from "./SideProjects.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const getProjects = graphql`
  query {
    allContentfulPridatNovyProjekt(
      limit: 4
      sort: { fields: createdAt, order: DESC }
    ) {
      projects: edges {
        node {
          slug
          id
          nazevProjektu
          hlavniFotka {
            description
            fluid(maxWidth: 150, quality: 45) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const SideProjects = ({ slug }) => {
  const {
    allContentfulPridatNovyProjekt: { projects },
  } = useStaticQuery(getProjects)

  // get 3 projects which doesnt equal current project
  const filtered = slug
    ? projects.filter(project => project.node.slug !== slug)
    : projects.slice(0, projects.length - 1)

  return (
    <div className={styles.projects}>
      <h3 className="sideHeading">Nejnovější realizace</h3>
      {filtered.map(project => {
        return (
          <Link
            key={project.node.id}
            className={styles.project}
            to={`/projekty/${project.node.slug}`}
          >
            <div className={styles.imageBox}>
              <Img
                className={styles.image}
                fluid={project.node.hlavniFotka.fluid}
                alt={project.node.hlavniFotka.description}
              />
            </div>
            <div className={styles.textWrapper}>
              <h4 className={styles.title}>{project.node.nazevProjektu}</h4>
              <div className={styles.showMore}>Zobrazit více</div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default SideProjects

SideProjects.propTypes = {
  slug: PropTypes.string,
}
